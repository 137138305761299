import Template from "../../index.tsx";
import picture from "../../../../assets/love_is_balance.jpeg";

export const header = `# Notes about Love

##### [Ru](/#/zametki_o_love)`;

export const content = `
###### The blessed words are different from yours

You are your self. Love lives within

There is no love where there is no trust

Love more powerful than fear

Love stands as weapon against fear. Fight with fear is inevitable where there is love.
There is no love without fighting fear

Fear takes away sanity. Love and trust bring it back

You appear before yourself

Love demands balance. Where there is no balance, love does not flourish


**poolsar.42 [at] gmail [dot] com**
`;

const Notes = () => {
  return Template(picture, header, content);
};

export default Notes;
