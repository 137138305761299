import Template from "../index.tsx";
import picture from "../../../assets/way.png";
import SheHelps from "../../../assets/she_helps.png";
import MakesOthersSin from "../../../assets/makes_others_sin.png";
import Chaotic1 from "../../../assets/chaoticOne.png";
import Chaotic2 from "../../../assets/chaoticTwo.png";
import Chaotic3 from "../../../assets/chaoticThree.png";

export const header = `# She`;

export const content = `
##### God, grant me the serenity to accept the things I cannot change, 
##### the courage to change the things I can
##### and the wisdom to know the difference
---
She wears a strawberry pink hair clip. Others told her it looks ugly

She hates it.

She explores for the better

--- 
She has a gift

She sees for others

She helps them search
![She Helps](${SheHelps})

---
She gets stuck on other paths.

She sins. 

She can't leave alone. 

She makes others sin.
![Makes others sin](${MakesOthersSin})

---
She hates.

She cries.

She fears.

She hopes

![Ways](${Chaotic1})
---
---
---
---
---
---
---
---
---
---
---
---
---
---
![Ways](${Chaotic2})
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
![Ways](${Chaotic3})
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---

---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---
---

Under her right cheek she holds vanilla ice cream. Dolphins carry her to the
middle of the ocean. Frog rests on her back. Pink hair clip shines under the sun

She feels her self like never before

She is happy


###### Inspired by Vladimir Sorokin's story "Love" published in "The First Subotnik" / "My First Working Saturday" storybook
###### По мотивам рассказа Владимира Сорокина ["Любовь"](https://vladimirsorokin.org/texts/persub_part18.shtml) из сборника рассказов "Первый субботник"
**poolsar.42 [at] gmail [dot] com**
`;

const She = () => {
  return Template(picture, header, content);
};

export default She;
