import AnimatedTemplate from "../aimatedIndex.tsx";
import minStolen from "../../../assets/minStolen.png";
export const header = ``;

// export const content = `


// I wanted to write a mini-history of the internet. How it all began, how
//  it ended up in its current state. How differs thoughts about it in different
//  places in the world. Like different blocks like in China and North Korea and Cuba and all that.
 
//  But I only found that these are very complex topics to cover all of them in just one blog-post.
//  All of these topics are covered somewhere by someone. So all I had to do was to go through all these literature
//  and combine key parts to my own post, right? After couple of weeks it became very hard task to do.
 
//  So I decided not to write about it and post all links from where I was reading instead. And here they are:
//  1) [This](https://www.webfx.com/blog/web-design/the-history-of-the-internet-in-a-nutshell/) article is trying to cover
//  all internet history briefly. One of the reasons I didn't do it is because it's already somewhat complete in this terms.
//  2) [Hell of a paper](https://nsarchive.gwu.edu/document/22835-document-01-defense-advanced-research-projects). This is unclassified docs
//  about ARPANET in it's beginning.
//  3) https://en.wikipedia.org/wiki/NASA_Deep_Space_Network
//  4) https://www.researchgate.net/profile/Weishan-Miao/publication/341283501_Who's_in_charge_of_regulating_the_Internet_in_China_The_history_and_evolution_of_China's_Internet_regulatory_agencies/links/5f179bf145851515ef3e313b/Whos-in-charge-of-regulating-the-Internet-in-China-The-history-and-evolution-of-Chinas-Internet-regulatory-agencies.pdf
//  5) https://en.wikipedia.org/wiki/History_of_the_Internet
//  6) https://en.wikipedia.org/wiki/Internet_in_China#:~:text=China%20has%20been%20on%20the,of%202018%2C%20has%20remained%20so.
//  7) https://en.wikipedia.org/wiki/Internet_censorship_in_China
//  8) https://en.wikipedia.org/wiki/Digital_divide
//  9) https://en.wikipedia.org/wiki/Global_digital_divide
//  10) https://en.wikipedia.org/wiki/Internet
//  11) https://en.wikipedia.org/wiki/ARPANET
//  12) https://en.wikipedia.org/wiki/Interplanetary_Internet
//  13) https://ru.wikipedia.org/wiki/%D0%9E%D0%B1%D1%89%D0%B5%D0%B3%D0%BE%D1%81%D1%83%D0%B4%D0%B0%D1%80%D1%81%D1%82%D0%B2%D0%B5%D0%BD%D0%BD%D0%B0%D1%8F_%D0%B0%D0%B2%D1%82%D0%BE%D0%BC%D0%B0%D1%82%D0%B8%D0%B7%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F_%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D0%B0_%D1%83%D1%87%D1%91%D1%82%D0%B0_%D0%B8_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8
//  14) https://en.wikipedia.org/wiki/History_of_the_Internet_in_Russia#:~:text=In%20the%20USSR%2C%20the%20first,but%20failed%20to%20be%20implemented.
//  15) https://en.wikipedia.org/wiki/Internet_protocol_suite
//  16) https://en.wikipedia.org/wiki/Tor_(network)#History
//  17) https://en.wikipedia.org/wiki/Dark_web
//  18) https://www.usg.edu/galileo/skills/unit07/internet07_01.phtml
//  19) https://lynx.invisible-island.net/

//  In time I'll go through all of that and explain a little bit about each article as if they were independent
//  (as they are in first approach)

// `;

export const content = [
  `.(｡^‿^)'`,
  `-(^0^)-`,
  `'(^‿^｡).`,
  `-(^0^)-`,
  `.(｡^‿^)'`,
  `-(^0^)-`,
  `'(^‿^｡).`,
  `-(^0^)-`,
];

const MinSteal = () => {
  return AnimatedTemplate(minStolen, header, content);
};

export default MinSteal;
