import "./styles/App.css";
import InternetStream from "./short/content/InternetStream.tsx";
import KademliaIntro from "./short/content/KademliaIntro.tsx";
import Minsteal from "./short/content/Minstreal/3_minStolen,.tsx";
import BacteriophagesOne from "./short/content/BacteriophagesOne.tsx";
import AIOne from "./short/content/aiOne.tsx";
import FearEn from "./short/content/fearEn.tsx";
import LoveNotes from "./short/content/LoveNotes.tsx";
import She from "./short/content/She.tsx";
import Jacob from "./short/content/Jacob.tsx";
import Lilith from "./short/content/lilith.tsx";
import React from "react";

function MobileWing() {
  return (
    <>
      <div className="blog-post">
        <InternetStream />
        <KademliaIntro />
        <Minsteal />
        <BacteriophagesOne />
        <AIOne />
        <FearEn />
        <LoveNotes />
        <She />
        <Jacob />
        <Lilith />
      </div>
    </>
  );
}

export default MobileWing;
