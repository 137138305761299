import "./styles/App.css";
import Navbar from "./Navbar.tsx";
import LeftWing from "./LeftWing.tsx";
import RightWing from "./RightWing.tsx";
import MobileWing from "./MobileWing.tsx";

import { useState, useEffect } from "react";
import React from "react";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

function App() {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  if (windowSize.innerHeight <= windowSize.innerWidth) {
    return (
      <>
        <div className="App">
          <LeftWing />
          <div className="app-navbar">
            <Navbar />
          </div>
          <RightWing />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="App">
          <div className="app-navbar">
            <Navbar />
          </div>
          <MobileWing />
        </div>
      </>
    );
  }
}

export default App;
