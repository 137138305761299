import "./styles/App.css";
import KademliaIntro from "./short/content/KademliaIntro.tsx";
import BacteriophagesOne from "./short/content/BacteriophagesOne.tsx";
import FearEn from "./short/content/fearEn.tsx";
import She from "./short/content/She.tsx";
import Lilith from "./short/content/lilith.tsx";
import React from "react";

function RightWing() {
  return (
    <>
      <div className="blog-post">
        <KademliaIntro />
        <BacteriophagesOne />
        <FearEn />
        <She />
        <Lilith />
      </div>
    </>
  );
}

export default RightWing;
