import ai_eye from "../../../assets/way.png";
import Template from "../index.tsx";

const header = `She`;

const description = "The way she goes";

const location = "/she";

const date = `November, 2024`;

const She = () => {
  return Template(ai_eye, header, description, location, date, false, [
    "Spirit",
    "Path",
  ]);
};

export default She;
