import Kademlia from "../../../assets/kademlia.png";
import Template from "../index.tsx";

const header = `
Intro to Kademlia
`;

const description = `
    Hello.
    This is an intro to Kademlia DHT
`;

const location = "/kademlia_intro";

const date = "July, 2022";

const KademliaIntro = () => {
  return Template(Kademlia, header, description, location, date, false, [
    "science", "DHT"
  ]);
};

export default KademliaIntro;
