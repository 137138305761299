import Template from "../../index.tsx";
import picture from "../../../../assets/love_is_balance.jpeg";

export const header = `# Заметки о Любви

##### [En](/#/notes_about_love)`;

export const content = `
###### Блаженного слова отличны от твоих

Ты есть ты и только. Любовь в тебе

Нет любви без доверия

Любовь сильнее страха

Любовь - оружие против страха. Борьба со страхом неизбежна при любви. Нет любви без борьбы со страхом

Страх сведёт с ума. Любовь и доверие вернут разум обратно

Ты предстанешь перед собой

Любовь требует равновесия. Где нет равновесия, любовь не цветёт


**poolsar.42 [at] gmail [dot] com**
`;

const Zametki = () => {
  return Template(picture, header, content);
};

export default Zametki;
