import ai_eye from "../../../assets/Ouroboros.png";
import Template from "../index.tsx";

const header = `Fear`;

const description = `What do you hate the most?`;

const location = "/fear_en";

const date = `November, 2024`;

const FearEn = () => {
  return Template(ai_eye, header, description, location, date, false, [
    "Spirit",
    "Fear",
  ]);
};

export default FearEn;
