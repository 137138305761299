import "./styles/nothing.css";
import React from "react";

const Nothing = () => {
  return (
    <div className="nothing">
      <span className="nothing-span"> Page</span> does not exist
    </div>
  );
};

export default Nothing;
