import React from "react";
import ghLogo from "../assets/gh.svg";
import Face from "../assets/LOGO1.png";

import "./styles/Navbar.css";

const Navbar = (props) => {
  return (
    <div className="navbar">
      <div className="navbar-header">
        <h3>pillowguy' s blog</h3>

        <div className="img-navbar">
          <a
            href="https://pillowboy.me"
            rel="noreferrer"
            target="_blank"
            className="navbar-element"
          >
            <img src={Face} className="navbar-pic" alt="milady eyes" />
          </a>
        </div>
        <div className="img-navbar">
          <a
            href="https://github.com/poolsar42"
            rel="noreferrer"
            target="_blank"
            className="navbar-element"
          >
            <img src={ghLogo} className="navbar-pic" alt="github logo" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
