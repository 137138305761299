import Template from "../index.tsx";
import picture from "../../../assets/jacob.png";
import MissingInSight from "../../../assets/missing_right_in_front.png";
import WhiteEye from "../../../assets/white_eye.png";

export const header = `# State Of Work`;

export const content = `
###### The reason I stay in bed all day is that I'm tired to sleep alive


Jacob finished his second cigarette and walked towards the entrance. Doors opened
in front of him, he slipped to the elevator and reached second floor. With coffee
made by machine he now is headed to his desk. He wears red checkered shirt and blue jeans.

Without looking at keyboard, he began to type words of machine code onto the screen. 
Routine is so familiar, Jacob no longer pays attention to details. 
He dissolves in them, he doesn't notice his eyes are stuck together by particles of fog.

Coffee reaches stomach, fuel starts the process. White eyes are open.
New line - new run. Computer *r*eads, *e*valuates and *p*rints results. *L*oop begins, repeats.

Jacob is always behind his self.

Jacob is always in front of his self.

He will go beers with friends tonight. New line. Tomorrow he needs to wear new polo.
Line. Read. Cindy has great shapes. These pants fit her perfectly. Eval. Function works.
Got the answer. New line.

![Missing Right In Front](${MissingInSight})
###### Art by [Irina Butkovskaya](https://www.instagram.com/p/Bwb5exLhhY9)

---
Why doesn't my decorator generalize? It should override types and insert them into the dictionary.
We were thinking about this design for long, implemented it, but the dictionary is still empty.

I will finish this part soon and you can say it is a proof of concept! Everybody tells
me how well I worked on the project. For all that, I can't lose filling it could've
been done better. In the end, this fucking decorator doesn't generalize. 
Although I have to give myself a credit - my design for both classes automated language
workflow to the childlike simplicity. Yet, why the hell haven't I been promoted?
Fucking decorator tells something about my skills. Managers know better.

Minimum viable product is almost ready. With it, I won't be ashamed to ask for raise.
Fucking decorator.

![White Eye](${WhiteEye})

---
To be sincere, I am not that good a programmer. I ended up here by accident.
And I absolutely do not understand why haven't they fired me.
My skillful ability to fool people will take me far. Even though it was an accident,
I'm not going to lose this job. Living in a garbage dump is not my goal.
Can this fucking decorator make me homeless?

No, I don't like these thoughts. If I get fired, I won't see Cindy's skirt anymore.
I can't let this happen, I have no choice. I won't allow any decorator do this.

I hate fucking policies of this company. Why can't I say to someone I like them?
Or to ask for a date or coffee? Though, probably, science knows it better. I heard 
research says personal connections drop income by 20% or something. Bad for business. So for me.

---
How the fuck could they possibly know what is better and worse for me? Yeah,
perhaps researchers know better. Science exists for a reason. But I want to ask her
for an evening for a second fucking year in a row. She managed to change two boyfriends
and has already broken up with the last one. All our conversations are "hello"s at coffee machine.
I have to read books on how to talk to girls. Those who know know and teach. But I have to
finish decorator first. I will talk to Cindy tomorrow.

Finished.

—  Mike, get some beer! Fuck this shit. 

**poolsar.42 [at] gmail [dot] com**
`;

const Jacob = () => {
  return Template(picture, header, content);
};

export default Jacob;
