import HopOne from "../../../assets/peer-routing/1-hop.svg";
import HopTwo from "../../../assets/peer-routing/2-hop.svg";
import HopThree from "../../../assets/peer-routing/3-hop.svg";
import FinalHop from "../../../assets/peer-routing/final-hop.svg";
import KademliaSystem from "../../../assets/peer-routing/Kademlia-system.svg";
import kBuckets from "../../../assets/peer-routing/k-buckets.svg";
import Kademlia from "../../../assets/kademlia.png";

import Template from "../index.tsx";

export const header = `

# Intro to Kademlia DHT
`;

export const content = ` 
###### *This article was supposed to be in libp2ps' [docs](https://docs.libp2p.io/concepts). But it turned out to be more serious than needed. Anyway, thanks for the great support to the people in this [thread](https://github.com/libp2p/docs/pull/147) :P*

One of the many mechanisms that libp2p uses for peer routing is Kademlia DHT. We will dive into how it works in this article, this will help you further understand more advanced topics.

## Static DHT

In this example, we are going to assume for now that our DHT is static, in the sense that no one joins DHT and no one leaves.

### System Description

When a peer is added to the DHT, each peer is assigned a peer ID. Kademlia provides an efficient lookup algorithm that locates successively “closer” nodes to any desired ID.

In Kademlia, peers are sorted as key-value pairs in the DHT. A peerID is stored as a key and the [address](https://docs.libp2p.io/concepts/addressing/) (or IP address) of a that peer stored as a value.

Kademlia treats peers as leaves in a binary tree. We store hashes of Peer IDs in leaves, which are represented as binary numbers. Notice, that we do not need to store the whole hash to specify the peer. We can store only prefix if prefix is unique.

Leaves on the tree that are not peers represent [content](https://docs.libp2p.io/concepts/content-routing/) which is stored by some close nodes.

![](${KademliaSystem})

The red node in this diagram has the prefix _001_. Traversing this graph from the top to the bottom along the edges, you will notice a blue node with the prefix _11101_, as well as yellow leaves which are other peers.

For any given node, Kademlia divides the whole binary tree into a series of successively lower subtrees. The highest subtree consists of the half of the binary tree not containing the original node. The next subtree consists of the half of the remaining tree not containing the next highest node, and so on.

You can see here for node _001_ there are three levels of subtrees are also circled, consisting of all nodes with prefixes _1_ (highest level subtree) , _01_, and _000_ (lowest level subtree).

The Kademlia protocol ensures that every node knows of at least one node in each of its subtrees, if that subtree contains a node. This guarantees that any node can be located by it's peer ID.

Let's see how once can locate in an example. We will call the red node 'Bob', with the prefix _001_. We will call the yellow node Alice, with the prefix _01_

_A brief reminder_: Alice's peerID is a key in the DHT, and the value of this key is Alice's IP address.

Bob can locate Alice in this example. Bob knows Alice's peerID and he needs to get her IP address. From the subtree with the prefix _1_ Bob knows a peer with prefix _101_. He sends a message to their address, where he asks for Alice's address.

![1-hop](${HopOne})

Peer _100_ doesn't have Alice's address, so they reroute and ask the peer located at the node with the prefix _110_ from their subtree.

![2-hop](${HopTwo})

In this example, peer _110_ also doesn't have Alice's address, so they send request to another node, with the prefix _1111_.

![3-hop](${HopThree})

Peer _1111_ stores Alices peer id and can send the key to the Bob! This is how routing works and here is full scheme:

![final-hop](${FinalHop})

Peer _1111_ sends response directly to Bob, since each request in this chain had Bob's address.

### Routing table

The place where one node stores info about other nodes is called a routing table. The routing table is a binary tree whose leaves are _k-buckets_.

### k-buckets

Take a look at the initial tree:
![Kademlia-system](${KademliaSystem})
See these circled sub-trees? Remember that the Kademlia protocol ensures that every node knows of (and can locate) at least one node in each of its subtrees. If that subtree contains a node. _k-bucket_ of a sub-tree is a set of that nodes. Once again: from each subtree we take only those nodes that we know - and we call it _k-bucket_. In out case _k-buckets_ for Bob look like this

![k-buckets](${kBuckets})



Each _k-bucket_ covers some range of the ID space, and together the _k-buckets_ cover the entire peer ID space with no overlap.

## Dynamic DHT

The gap from our current state to a fully dynamic DHT is not as far away as it seems. Let us first consider how we can extend this protocol to support computers sporadically leaving.

### Peers Leaving

Let us assume that we have some known parameter _k_ that represents some given number of computers. This number should be a value such that it is extremely unlikely that all of those computers will leave the network in the same hour. The original Kademlia paper that the _k_ value should be 20.

Next, our _k-bucket_ should, instead of storing just 1 computer, store _k_ computers within that _k-bucket’s_ range. Thus, with high likelihood, there will always be at least one computer online in each of a computer’s _k-buckets_.

And, when hopping, instead of asking one computer from the _k-bucket_ we will be asking _k_ number of peers. Some may not reply, but not all.

To check if each node is still alive we can ping it periodically. If node doesn't respond in the allotted time it will be marked as non-responding and eventually replace it with another.

How do we become aware of new computers that could fit into our unfilled _k-buckets_? We can perform lookups on random ids within a _k-bucket’s_ id range (the range is defined by all leaves that are direct descendants of the _k-bucket’s_ corresponding internal node, which is not a leaf, in the complete tree) and thus learn about other computers within that _k-bucket_, if they exist. This is called a _bucket refresh_.

### Peers Joining

To join the DHT new computer, which we will call *Eve*, must know at least on IP address of a computer which is in the network. Then _Eve_ will perform a lookup of herself to get closest known computers which are already aware of _Eve_. After that _Eve_ will perform a _bucket refresh_ for all _k-buckets_ from the closest known computers to _Eve_.

This will populate the _Eve's_ routing table based on the correctness of the lookup algorithm and the fact that the routing tables of other computers are sufficiently populated. In addition, all computers that are being queried by _Eve_ will become aware of _Eve_ in the process and thus will have the opportunity to insert _Eve_ into their own _k-buckets_ if their corresponding bucket is not already full.

## More information:

- It will be useful to read the original Kademlia [whitepaper](https://pdos.csail.mit.edu/~petar/papers/maymounkov-kademlia-lncs.pdf).

- [libp2p Kademlia specification](https://github.com/libp2p/specs/blob/master/kad-dht/README.md)

- In addition take a look at [libp2p kademlia implementation on go](https://github.com/libp2p/go-libp2p-kad-dht).

- For more detailed informationg check out the article from [Stanford Code the Change](https://codethechange.stanford.edu/guides/guide_kademlia.html).

`;

const KademliaIntro = () => {
  return Template(Kademlia, header, content);
};

export default KademliaIntro;
