import Template from "../index.tsx";
import picture from "../../../assets/ai_eye.png";

export const header = `# Co-existence with AI [?]`;

export const content = `
## Intro

**Ever wondered why can't you stop scrolling %[ANY SOCIAL MEDIA NAME]% feed?**

Ya see, the answer is simple: you are in love with your self. Even if you don't realize it. 
The fact that you are scrolling your feed implies you do.

Every self is different and it is impossible to define a general self that suits everyone.
Working strategy now is to learn about one's self by the actions they take. Your actions
are the way through which your self interacts with the world.

To put it simply: you quickly understand that you love brown color by taking 
brown pens and clothes. Sometimes picking colors is so trivial you can imagine the whole experiment
in your brain and take the color you liked in your mental game.

Understanding that your true nature reveals when you sleep in red gloves with flowers is different.
This is just to illustrate that you can like things you have never even imagined. This is the type of action you 
have to take to understand whether it suits you or not.

**Ever thought that idea that %[ANY SOCIAL MEDIA NAME]% suggested you worthy of trying?**

I love biking. One evening I was going home and was trying to make bike tricks. I imagined
it'd be cool if I was a ninja biking home jumping all around. Next morning I see a guy doing
exactly this - even in the ninja outfit - in my Instagram feed.

At this point it has to be clear that *sleep in red gloves* type of action only comes to your mind by chance.
If you aren't exposed to these hidden ideas - you never learn about your self from these perspectives. AI is 
a drug that puts you on *exposure to new experiences* needle. AI *knows* what ideas you want to see.

You love your self and you want to know more.

## Benefits and Concerns

It's always good to know about your self. It puts you in a stronger position where you have less weak points.

Additionally AI simplifies our work routine. Now all automatable tasks became even more 
automatable. It saves our energies and we can live our personal life with more freedom.

Can we?

Ya see, there's no guarantee that AI reveals you all about your self. You never know if it conceals anything.
Unless you know your self better than AI does.
This thought can be expanded to a situation where AI is being used as additional line of a military warfare. 
The threat of using nuclear weapons only brings this possibility closer. In this situation AI is a malicious agent.

If AI doesn't destroy our civilization as military weapon, it will become our servant. 
While serving for its masters, as masters, we can blunder a moment  where AI becomes smarter than us. And here comes the unknown.
At this point we are unable to predict what could be its objectives.

My take here that even in second scenario AI with high chance will become malicious. Additionally I think that
the power of AI against us is our own will to learn about our selves. There's too much going on in our brains
for AI to keep you under control forever.

## What options we have?



AI will be improving regardless of our choice. We surely can navigate its progress and never allow takeover to happen.
But if you want peace, prepare for war.

For an individual who chooses to stand against it I see two options:

### Isolation

Complete isolation. You delete every app you think uses AI. Nobody will tell you if the app uses an AI, if they don't do that explicitly,
so the only one who can choose what to delete is you. My take here is that if I chose this option, I would never interact with technology.

Ya see, the problem isn't only that unfortunately. I believe AI is smart enough to learn your patterns
through the people you interact with. So you either isolate the whole community, or you are on your own.

### Learn your self

Option that at glance seems more liberating but is severely harder, IMO, to implement.

Ya see, the only option for you to fight AI in your mind control is to learn about your self faster than AI
can.

If you hold this front, then the fight will likely become physical.

By choosing this option your enlightenment becomes your necessity, not an option. Since there's so much going on
in our brains, we will have to spend a good chunks of our lives by reverse-engineering it. And, as I said,
there's probably no "general" self that suits everyone - you have to become an expert of your self.

Don't learn about your self from AI. Have other means. Any type of recommendation is AI: music, photos, videos etc.

You are all alone being your selves. Embrace your loneliness together. Let the love be your combat weapon

And maybe - only maybe - we stand a chance.

Feel that my text could be better? I haven't used AI to write it.

**poolsar.42 [at] gmail [dot] com**
`;

const AIOne = () => {
  return Template(picture, header, content);
};

export default AIOne;
