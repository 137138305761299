import ai_eye from "../../../assets/love_is_balance.jpeg";
import Template from "../index.tsx";

const header = `Notes about Love`;

const description =
  "Simple rules";

const location = "/notes_about_love";

const date = `November, 2024`;

const LoveNotes = () => {
  return Template(ai_eye, header, description, location, date, false, [
    "Spirit",
    "Love",
  ]);
};

export default LoveNotes;
