import "./styles/App.css";
import InternetStream from "./short/content/InternetStream.tsx";
import Minsteal from "./short/content/Minstreal/3_minStolen,.tsx";
import AIOne from "./short/content/aiOne.tsx";
import LoveNotes from "./short/content/LoveNotes.tsx";
import Jacob from "./short/content/Jacob.tsx";
import React from "react";

function LeftWing() {
  return (
    <>
      <div className="blog-post">
        <InternetStream />
        <Minsteal />
        <AIOne />
        <LoveNotes />
        <Jacob />
      </div>
    </>
  );
}

export default LeftWing;
