import Template from "../index.tsx";
import picture from "../../../assets/lilith.png";

export const header = `# Lilith`;

export const content = `
###### Please don't read from phone. It breaks paintings

---
###### Once per day or once per week she breaks your babies like a stick

She is so pretty.

How does she do this? Every time I think is the last one. And here I am again.

Can't get her out of my head. She always comes in different guises. Although no.
Sometimes she repeats herself. Yes. Some times she is even indistinguishable from before.

Nothing stops me. Nothing possibly can.

I remember well first time she came. Although vaguely. Many others erased from memory.

---
#### *

THE ORIGINAL HEBREW WORD FROM WHICH THE NAME LILITH IS TAKEN IS IN THE BIBLICAL HEBREW,
IN THE **BOOK OF ISAIAH**, THOUGH LILITH HERSELF IS NOT MENTIONED IN ANY BIBLICAL TEXT.

LILITH IS CITED AS HAVING BEEN "BANISHED" FROM THE GARDEN OF EDEN FOR NOT COMPLYING
WITH AND OBEYING ADAM.

SHE IS MENTIONED IN **BABYLONIAN TALMUD** (ERUVIN 100b, NIDDAH 24b, SHABBAT 151b,
BAVA BATRA 73a), IN THE CONFLICT OF ADAM AND EVE WITH SATAN AS ADAM'S FIRST WIFE,
AND IN THE **ZOHAR** § LEVITICUS 19a AS "A HOT FIERY FEMALE WHO FIRST COHABITED WITH MAN".

LILITH MEANS "NIGHT BIRD"

**ALPHABET OF SIRACH**

FIRST WIFE OF ADAM WHO WAS CREATED AT THE SAME TIME AND FROM
THE SAME CLAY AS ADAM. IN THE 13TH-CENTURY WRITINGS OF ISAAC BEN JACOB HA-COHEN,
LILITH LEFT ADAM AFTER SHE REFUSED TO BECOME SUBSERVIENT TO HIM AND SHE WOULD NOT RETURN
TO THE GARDEN OF EDEN AFTER SHE HAD COUPLED WITH THE ARCHANGEL **SAMAEL**.

"NIGHT CREATURES", "NIGHT MONSTER", "NIGHT HAG", "SCREECH OWL"

JEWISH RABBIS IN **TALMUDIC TEXTS** FEARED THE LIKES OF LILITH, SOME TO SUCH AN EXTENT
THAT THEY RECOMMENDED MEN NOT TO SLEEP IN A HOME ALONE, AS THEY WHO DO WOULD BE 
"SEIZED BY LILITH".

"SPIRIT", "FEMALE NIGHT BEING/DEMON"

**DEAD SEA SCROLLS**

HER HOUSE SINKS DOWN TO DEATH,

AND HER COURSE LEADS TO THE SHADES.

ALL WHO GO TO HER CANNOT RETURN

AND FIND AGAIN THE PATHS OF LIFE.

— PROVERBS 2:18–19

---
Although no. She is always close. There was never a first arrival. There was first
time she closed my heart.

She is so pretty. She is always who I want to see her. She doesn't say unnecessary words.
She doesn't remain silent when I want to hear her. Her sweet voice.

It's cold to sit on the tiled floor. I'm tired of standing, my hand is starting to go numb.
I'm not afraid to suffer the cold. I'm afraid to burn under her ire.

She doesn't let go. I always thought I knew how to leave. Apparently I was wrong.
Running away from her smile I jump into her arms. It never stops.

But this is not love.

Maybe love, I don't know. Every time she comes feels accidental. It slips in past
without me living through. 

Is this love? *Or is this lust?*

---
#### **

IF WOMAN WAS CREATED FROM ADAM, AFTER HIS INITIAL CREATION, THAN WHAT HAPPENED TO THE
FEMALE CREATED AT FIRST? THE ANSWER, ACCORDING TO **MIDRASH**, WAS THAT SHE WAS LILITH;
CREATED WITH ADAM, SHE REFUSED TO COMPLY WITH ADAM'S DEMAND THAT SHE SUBMIT HERSELF
TO HIM, AND IN THE END FLED FROM HIM BY USING INEFFABLE NAME. ADAM THEN COMPLAINED TO
GOD ABOUT HIS LONELINESS, AND THE CREATION OF EVE FOLLOWED. LILITH BORE ADAM A NUMBER
OF CHILDREN WHO BECAME THE DEMONS. LILITH STILL PRODUCES CHILDREN, ACCORDING TO MUCH
LATER **KABBALISTIC ELABORATION**. THIS DEMONS ARE THE CHILDREN OF MEN, AS HER ORIGINAL
OFFSPRING WERE CHILDREN OF ADAM, LILITH BEING IMPREGNATED BY THE SEMEN PRODUCED BY
MASTURBATION AND NOCTURNAL EMISSIONS.

AS MAY BE SURMISED, THE RABBINIC SOURCES DO NOT PRESENT LILITH IN A FAVORABLE LIGHT
(ALTHOUGH ADAM DOES NOT COME OFF TOO WELL EITHER); THEY WERE CONTENT TO SEE HER AS
FILLING OUT THE BIBLICAL PICTURE, AND AS THE ARCHETYPE OF THE "BAD WOMAN". MODERN 
FEMINISTS, ESPECIALLY JEWISH FEMINISTS, HAVE TRIED TO SHOW HER AS UNE FEMME IDEAL,
DRAWING PARTICULARLY ON HER SPURNING OF ADAM'S ATTEMPTED DOMINANCE, AND TRIED TO 
ESTABLISH HER PRESENCE AMONG THE PLANETS. BUT LILITH'S **BABALONIC** IDENTITY GOES FURTHER
THAN THAT; AND THE FULL LEGEND CAN PROFITABLY COMMENT ON BABALON.

AS ADAM'S "OTHER HALF" (LITERALLY), THE WHOLE ADAM MUST INCLUDE HER. THE ANDROGYNOUS/HERMAPHRODITIC
ADAM-LILITH IN UNION WAS THE ORIGINAL FORM OF HUMANITY. SUCH A CONJUNCTION IS NOT CONDUCTIVE
TO PROCREATION, AND THUS THE TWO HALVES WERE DIVIDED. ADAM COULD SEE LILITH NOT ONLY 
FRONT TO FRONT BUT ALSO IN THE ROUND (AND SHE HIM); BUT NOW THEY MUST MAKE AN EFFORT TO
UNITE. AND HERE HE BLUNDERED; INSTEAD OF ACCEPTING HER AS AN EQUAL, HE ATTEMPTED TO DOMINATE.

---
— i've never been this down before in my life. you can be lost in her eyes and voice.
She's freaking gorgeous 𓆩♡𓆪 

#### 10
— i found you again!!

*Perfect! 𖣐 Want to know what else you can do for me again?*

*I think you know.*

*Yeah. That. Go on. Do it. 𓇢𓆸*

#### 9
⠀⠀⢠⣤⣄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⢀⡟⠀⣿⡄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⢠⡾⠁⠀⣿⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣀⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⣾⠁⣤⣼⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⣴⠟⠉⠙⠳⣦⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⣿⠀⠀⢸⡇⠀⢀⡶⠛⢶⣶⢶⣶⣶⡿⠶⣤⣤⣼⡇⠉⣛⠶⣄⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⣿⠀⠀⠘⣧⣀⢸⡇⠀⣼⠁⠀⠀⡟⠀⠀⣤⡀⢉⡹⣟⠉⠀⠈⠻⢦⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⣏⠀⠐⠟⠛⠛⢿⡇⠀⣿⠀⠀⢸⡇⠀⠀⢘⣷⡿⠁⢻⡀⠀⠀⠀⠀⠙⣧⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠙⢷⣤⡀⠀⠀⠘⣇⠀⣿⠀⠀⢸⡇⠀⠀⠘⢿⣿⣄⢸⡇⠀⠀⠀⠀⠀⠈⢻⡄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠈⠛⢦⣄⠀⢿⡀⢹⡄⠀⢸⡇⠀⠀⠀⠀⠙⠿⠿⠷⠶⠶⠦⣤⣀⠀⠀⠻⣦⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠙⢷⣼⡇⠸⣧⠀⠈⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠉⠀⠀⠀⠈⠻⣦⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠙⣷⠀⣿⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠙⠳⣦⡀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⢹⣆⡹⣦⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠙⠷⣤⣀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠉⠛⠛⠛⠛⠷⠶⠶⢤⣤⣤⣀⣀⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠙⠳⣦⣀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠉⠉⠙⠛⠲⢦⣄⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠉⠛⠶
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠛⠷⣤⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠙⠷⣤⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠙⠷⣤⡀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠛⢶⣄⡀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠻⢦⣄⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠙⠳⣦

#### 8
*It was about time I was filming video and he .. completed.*

*Uhm. In the back. And it shout out of my nose. I thought it was boogers.*

*it was not*

#### 7
*You look good down there*

#### 6
*Baby you are so right they were totally using aimbot. You should've won that game.*

*keep going and you will feel better*

#### 5
*faster*

#### 4
### faster

#### 3
— girl do you blink?..

*No, I don't. Eye contact is very important and I wouldn't want to ruin it.*

#### 2
´ཀ\`

#### 1
 𓆩♡𓆪

 𖣐 

𓇢𓆸

*Good boy. You are such a good boy.*


###### * Excerpts from [Wikipedia Lilith page](https://en.wikipedia.org/wiki/Lilith#)
###### ** Excerpts from [Lilit, Malkah ha-Shadim by Jeffrey Smith](https://web.archive.org/web/20060104084343/http://www.lilitu.com/lilith/lilit.html)

**poolsar.42 [at] gmail [dot] com**
`;

const Lilith = () => {
  return Template(picture, header, content);
};

export default Lilith;
