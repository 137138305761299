import Template from "../../hidden_template.tsx";
import picture from "../../../../assets/future_revelation.png";

export const header = `# ОТКРОВЕНИЕ`;

export const content = `
### О будущем

Утверждается что судный день наступает в будущем

Будущего не существует. Прошлого тоже

Есть момент. Одно здесь и одно сейчас

Судный день наступает здесь и сейчас

С ним и пришествие

**poolsar.42 [at] gmail [dot] com**
`;

const RevelationFutureRu = () => {
  return Template(picture, header, content);
};

export default RevelationFutureRu;
