import "./styles/preview.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkImages from "remark-images";
import remarkMath from "remark-math";
import rehypeHighlight from "rehype-highlight";
import rehypeKatex from "rehype-katex";
import React from "react";
import ghLogo from "../assets/gh.svg";
import Face from "../assets/LOGO1.png";
import { Link } from "react-router-dom";
import { useState } from "react";

// `rehype-katex` does not import the CSS for you
import "katex/dist/katex.min.css";

const Preview = () => {
  const [header, setHeader] = useState("header textarea");
  const [content, setContent] = useState("***content*** textarea");
  const [image, setImage] = useState(
    "https://bafybeigh2avgr6jgs2oksw56klz4vea6stpc65qapxtll2v36pxoymneuy.ipfs.w3s.link/LOGO.png"
  );

  const changeHeader = (evt) => {
    setHeader(evt.target.value);
  };

  const changeContent = (evt) => {
    setContent(evt.target.value);
  };

  const changeImage = (evt) => {
    setImage(evt.target.value);
  };

  return (
    <>
      <div className="preview-container">
        <div className="input-preview">
          <div className="note">
            Notice that <span className="nothing-span">markdown</span> is
            available <span className="nothing-span">only</span> for content
            textearea
          </div>
          <div className="preview">
            <h3>Set your image URL here</h3>
            <textarea
              value={image}
              className="header-preview"
              onChange={changeImage}
            />
            <h3>Set your header and content here</h3>
            <textarea
              value={header}
              className="header-preview"
              onChange={changeHeader}
            />
            <textarea
              value={content}
              className="content-preview"
              onChange={changeContent}
            />
          </div>
        </div>
        <div className="showcase-preview">
          <div className="container">
            <img className="image-long" src={image} alt="baby_steps pic" />
          </div>
          <div className="content-long">
            <div className="header-long">
              <h1>{header}</h1>
            </div>
            <div className="content-content-long">
              <div className="text-long">
                <ReactMarkdown
                  children={content}
                  remarkPlugins={[remarkGfm, remarkImages, remarkMath]}
                  rehypePlugins={[rehypeHighlight, rehypeKatex]}
                  components={{
                    img: ({ node, ...props }) => (
                      <img
                        style={{ maxWidth: "100%" }}
                        {...props}
                        alt="depicted graph"
                      />
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-footer">
        <div className="img-long">
          <a href="https://pillowboy.me" rel="noreferrer" target="_blank">
            <img src={Face} className="long-pic" alt="milady eyes" />
          </a>
        </div>
        <Link to="/">
          <button className="button-back-long">back</button>
        </Link>
        <div className="img-long">
          <a
            href="https://github.com/poolsar42"
            rel="noreferrer"
            target="_blank"
          >
            <img src={ghLogo} className="long-pic" alt="github logo" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Preview;
