import ai_eye from "../../../assets/lilith.png";
import Template from "../index.tsx";

const header = `Lilith`;

const description = `You are not alone.
`;

const location = "/lilith";

const date = `December, 2024`;

const Lilith = () => {
  return Template(ai_eye, header, description, location, date, false, [
    "Spirit",
    "Lilith",
    "don't read from phones. breaks paintings",
  ]);
};

export default Lilith;
