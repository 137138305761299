import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import App from "./App.tsx";
import "./styles/App.css";
import InternetStream from "./long/content/InternetStream.tsx";
import KademliaIntro from "./long/content/KademliaIntro.tsx";
import AIOne from "./long/content/AI1.tsx";
import MinSteal from "./long/content/3_minStolen,.tsx";
import Nothing from "./Nothing.tsx";
import Preview from "./Preview.tsx";
import BacteriophagesOne from "./long/content/Bacteriophages_1.tsx";
import Zametki from "./long/content/Love/Zametki.tsx";
import Notes from "./long/content/Love/Notes.tsx";
import RevelationFutureRu from "./long/content/Revelations/future_ru.tsx";
import FearEn from "./long/content/Fear_en.tsx";
import She from "./long/content/She.tsx";
import Jacob from "./long/content/Jacob.tsx";
import Lilith from "./long/content/Lilith.tsx";

const ERoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/internet_stream" element={<InternetStream />} />
        <Route path="/kademlia_intro" element={<KademliaIntro />} />
        <Route path="/minified_steal" element={<MinSteal />} />
        <Route path="/home" element={<App />} />
        <Route path="/" element={<Navigate replace to="/home" />} />
        <Route path="/preview" element={<Preview />} />
        <Route path="/ai_intro" element={<AIOne />} />
        <Route path="*" element={<Nothing />} />
        <Route path="/bacteriophages_intro" element={<BacteriophagesOne />} />
        <Route path="/zametki_o_love" element={<Zametki />} />\
        <Route path="/notes_about_love" element={<Notes />} />
        <Route path="/revelation_future_ru" element={<RevelationFutureRu />} />
        <Route path="/fear_en" element={<FearEn />} />
        <Route path="/she" element={<She />} />
        <Route path="/jacob" element={<Jacob />} />
        <Route path="/lilith" element={<Lilith />} />
      </Routes>
    </div>
  );
};

export default ERoutes;
