import ai_eye from "../../../assets/ai_eye.png";
import Template from "../index.tsx";

const header = `AI 1`;

const description = ` [Potentially] start of series on AI warfare and 
defense`;

const location = "/ai_intro";

const date = `November, 2024`;

const AIOne = () => {
  return Template(ai_eye, header, description, location, date, false, [
    "Spirit",
    "AI",
  ]);
};

export default AIOne;
