import Template from "../index.tsx";
import picture from "../../../assets/Ouroboros.png";

export const header = `# Fear`;

export const content = `
###### What do you hate the most?

In the Bible, a serpent tempts Adam and Eve to eat the forbidden fruit from the
tree of knowledge of good and evil in the Garden of Eden.

Since then, the snake has become evil in our minds. Here I call this evil **Fear**

You sin because you fear.

Sin is a deed against your will. A deed against your self.

When we do what we do not desire - we sin. We do this because we fear.

I share a weapon. Look at the image.

And never forget.

**poolsar.42 [at] gmail [dot] com**
`;

const FearEn = () => {
  return Template(picture, header, content);
};

export default FearEn;
