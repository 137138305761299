import "../styles/long.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkImages from "remark-images";
import remarkMath from "remark-math";
import rehypeHighlight from "rehype-highlight";
import rehypeKatex from "rehype-katex";
import React from "react";

// `rehype-katex` does not import the CSS for you
import "katex/dist/katex.min.css";

const Template = (image: string, header: string, content: string) => {
  return (
    <>
      <div className="container">
        <img className="image-long" src={image} alt="pic" />
      </div>
      <div className="content-long-hidden">
        <div className="header-long">
          <ReactMarkdown
            children={header}
            remarkPlugins={[remarkGfm, remarkImages]}
            rehypePlugins={[rehypeHighlight, rehypeKatex]}
          />
        </div>
        <div className="content-content-long">
          <div className="text-long">
            <ReactMarkdown
              children={content}
              remarkPlugins={[remarkGfm, remarkImages, remarkMath]}
              rehypePlugins={[rehypeHighlight, rehypeKatex]}
              components={{
                img: ({ node, ...props }) => (
                  <img
                    style={{ maxWidth: "100%" }}
                    {...props}
                    alt="depicted graph"
                  />
                ),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Template;
