import ai_eye from "../../../assets/jacob.png";
import Template from "../index.tsx";

const header = `State Of Work`;

const description = `He swims in foam`;

const location = "/jacob";

const date = `November, 2024`;

const Jacob = () => {
  return Template(ai_eye, header, description, location, date, false, [
    "Spirit",
    "Limbo",
  ]);
};

export default Jacob;
