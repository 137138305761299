import "../styles/long.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkImages from "remark-images";
import remarkMath from "remark-math";
import rehypeHighlight from "rehype-highlight";
import rehypeKatex from "rehype-katex";
import React from "react";
import ghLogo from "../../assets/gh.svg";
import Face from "../../assets/LOGO1.png";
import { Link } from "react-router-dom";

// `rehype-katex` does not import the CSS for you
import "katex/dist/katex.min.css";

const Template = (image: string, header: string, content: string) => {
  return (
    <>
      <div className="container">
        <img className="image-long" src={image} alt="logo" />
      </div>
      <div className="content-long">
        <div className="header-long">
          <ReactMarkdown
            children={header}
            remarkPlugins={[remarkGfm, remarkImages]}
            rehypePlugins={[rehypeHighlight, rehypeKatex]}
          />
        </div>
        <div className="content-content-long">
          <div className="text-long">
            <ReactMarkdown
              children={content}
              remarkPlugins={[remarkGfm, remarkImages, remarkMath]}
              rehypePlugins={[rehypeHighlight, rehypeKatex]}
              components={{
                img: ({ node, ...props }) => (
                  <img
                    style={{ maxWidth: "100%" }}
                    {...props}
                    alt="depicted graph"
                  />
                ),
              }}
            />
          </div>
        </div>
      </div>
      <div className="container-footer">
        <div className="img-long">
          <a href="https://pillowboy.me" rel="noreferrer" target="_blank">
            <img src={Face} className="long-pic" alt="milady eyes" />
          </a>
        </div>
        <Link to="/">
          <button className="button-back-long">back</button>
        </Link>
        <div className="img-long">
          <a
            href="https://github.com/poolsar42"
            rel="noreferrer"
            target="_blank"
          >
            <img src={ghLogo} className="long-pic" alt="github logo" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Template;
